<template>
  <div class="report">
    <Header />
    <v-container class="my-3">
      <v-row>
        <template v-if="!onLiveEditing">
          <template v-if="isLoaded">
            <v-col cols="12">
              <h2>Editing {{ extendedReport.course.title }}</h2>
              <p class="mb-0">
                <strong>Report ID:</strong>
                #{{ extendedReport.report.id }}
              </p>
              <p class="mb-0">
                <strong>Date Created:</strong>
                {{ extendedReport.report.created_at }}
              </p>
              <p>
                <strong>Prepared For:</strong>
                {{ extendedReport.report.organization_name }}
              </p>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="my-8 v-divider theme--light"
              />

              <div class="d-flex justify-space-between my-8">
                <v-btn
                  v-if="this.extendedReport.report.status == 'Submitted'"
                  rounded
                  depressed
                  color="tertiary"
                  to="/org-dashboard"
                  >Cancel</v-btn
                >
                <v-btn
                  v-else
                  rounded
                  depressed
                  color="tertiary"
                  to="/org-dashboard"
                  >Back</v-btn
                >
                <v-btn
                  v-if="this.extendedReport.report.status == 'Submitted'"
                  rounded
                  color="primary"
                  :disabled="conditionsToSendReport"
                  @click="updateReport()"
                  >Publish report</v-btn
                >
              </div>

              <v-card elevation="8">
                <v-toolbar flat color="progress" dark>
                  <v-toolbar-title>Edit Report</v-toolbar-title>
                </v-toolbar>
                <v-tabs :vertical="isMobile" class="pb-8">
                  <template
                    v-for="(part, iReport, iIndex) in extendedReport.quiz"
                  >
                    <v-tab :key="iReport" class="justify-start pa-3">
                      <!--flag for a wrong answered quiz-->
                      <v-icon
                        v-show="validateReport(iReport)"
                        color="error"
                        class="mx-2"
                        >mdi-alert</v-icon
                      >
                      Part {{ iIndex + 1 }}:
                      {{ part.info.started_heading }}</v-tab
                    >
                    <v-tab-item eager :key="iReport">
                      <v-card flat>
                        <v-card-text>
                          <h3>
                            Risk Assessment:
                            <span
                              :class="{
                                'success--text': riskAssessment == 'Low',
                                'warning--text': riskAssessment == 'Moderate',
                                'error--text': riskAssessment == 'High'
                              }"
                            >
                              {{ riskAssessment }}
                            </span>
                          </h3>
                          <p>
                            <strong>
                              Final Risk Score:
                              <span
                                :class="{
                                  'success--text': riskAssessment == 'Low',
                                  'warning--text': riskAssessment == 'Moderate',
                                  'error--text': riskAssessment == 'High'
                                }"
                              >
                                {{ riskScore }}
                              </span>
                            </strong>
                          </p>
                          <p>
                            Expand a question to preview and edit responses.
                            Once you have finished editing, click
                            <i>Publish Report</i> to generate your new report.
                            You will then be able to access your new report from
                            your Dashboard.
                          </p>

                          <v-expansion-panels v-model="openQuestion" focusable>
                            <v-expansion-panel
                              v-for="(currentQuestion,
                              iQuestion) in part.question"
                              :key="iQuestion"
                            >
                              <template
                                v-if="
                                  conditionCheck(
                                    currentQuestion.question_conditions,
                                    currentQuestion,
                                    iReport
                                  )
                                "
                              >
                                <v-expansion-panel-header
                                  disable-icon-rotate
                                  expand-icon=""
                                >
                                  <template v-slot:default="{ open }">
                                    <div
                                      class="d-flex justify-lg-space-between"
                                    >
                                      <div>
                                        <h3 class="text-h5 mb-3">
                                          <!--                                        <div-->
                                          <!--                                                class="text-break"-->
                                          <!--                                          v-html="currentQuestion.content"-->
                                          <!--                                        ></div>-->
                                          <computedQuestion
                                            :codeProps="
                                              currentQuestion.content_special
                                            "
                                          />
                                        </h3>
                                      </div>
                                      <div style="min-width: 100px">
                                        <v-icon
                                          color="success"
                                          class="mx-2"
                                          v-if="
                                            currentQuestion.required &&
                                              validationInput(
                                                iReport,
                                                iQuestion
                                              )
                                          "
                                          >mdi-check-circle</v-icon
                                        >
                                        <v-icon
                                          color="error"
                                          class="mx-2"
                                          v-if="
                                            currentQuestion.required &&
                                              !validationInput(
                                                iReport,
                                                iQuestion
                                              )
                                          "
                                          >mdi-alert</v-icon
                                        >
                                        <v-icon v-show="!open" class="ml-2"
                                          >mdi-plus-box</v-icon
                                        >
                                        <v-icon v-show="open" class="ml-2"
                                          >mdi-minus-box</v-icon
                                        >
                                      </div>
                                    </div>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                  <!-- if question type is a text field -->

                                  <div class="d-xl-flex align-center row">
                                    <div
                                      class="col-sm-11"
                                      v-if="currentQuestion.type === 'text'"
                                    >
                                      <v-text-field
                                        :disabled="
                                          extendedReport.report.status !=
                                            'Submitted'
                                        "
                                        class="pt-5"
                                        v-model="
                                          extendedReport.quiz[iReport].question[
                                            iQuestion
                                          ].answer
                                        "
                                        :rules="[
                                          v => !!v || 'Answer is required'
                                        ]"
                                        @input="
                                          updateResultReport(
                                            iReport,
                                            iQuestion,
                                            currentQuestion
                                          )
                                        "
                                        label="Please enter"
                                        filled
                                        :required="currentQuestion.required"
                                      ></v-text-field>
                                    </div>

                                    <!-- if question type is a textarea -->
                                    <div
                                      class="col-sm-11"
                                      v-if="currentQuestion.type === 'textarea'"
                                    >
                                      <v-textarea
                                        class="pt-5"
                                        :disabled="
                                          extendedReport.report.status !=
                                            'Submitted'
                                        "
                                        v-model="
                                          extendedReport.quiz[iReport].question[
                                            iQuestion
                                          ].answer
                                        "
                                        :rules="[
                                          v => !!v || 'Answer is required'
                                        ]"
                                        label="Please enter"
                                        value="Please enter"
                                        @input="
                                          updateResultReport(
                                            iReport,
                                            iQuestion,
                                            currentQuestion
                                          )
                                        "
                                        filled
                                        :required="currentQuestion.required"
                                      ></v-textarea>
                                    </div>

                                    <!-- if question type is radio buttons -->
                                    <div
                                      class="col-sm-11"
                                      v-if="currentQuestion.type === 'radio'"
                                    >
                                      <v-radio-group
                                        :disabled="
                                          extendedReport.report.status !=
                                            'Submitted'
                                        "
                                        v-model="
                                          extendedReport.quiz[iReport].question[
                                            iQuestion
                                          ].answer_response_id
                                        "
                                        column
                                        :rules="[
                                          v => !!v || 'Answer is required'
                                        ]"
                                      >
                                        <div
                                          v-for="item in currentQuestion.possible_responses"
                                          :key="item.id"
                                          class="mb-2 d-flex align-center"
                                          @click="
                                            updateResultReport(
                                              iReport,
                                              iQuestion,
                                              currentQuestion,
                                              item.id
                                            )
                                          "
                                        >
                                          <v-radio
                                            :label="item.content"
                                            :value="item.id"
                                            color="secondary"
                                            :required="currentQuestion.required"
                                          ></v-radio>
                                          <div
                                            class="mb-2 ml-1"
                                            v-if="item.help_text"
                                          >
                                            <v-tooltip bottom>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-icon
                                                  color="primary"
                                                  dark
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  mdi-alert-circle-outline
                                                </v-icon>
                                              </template>
                                              <span>{{ item.help_text }}</span>
                                            </v-tooltip>
                                          </div>
                                        </div>
                                      </v-radio-group>
                                    </div>

                                    <!-- if question type is checkboxes -->
                                    <div
                                      class="col-sm-11"
                                      v-if="currentQuestion.type === 'checkbox'"
                                    >
                                      <div
                                        v-for="item in sortedResponses(
                                          currentQuestion
                                        )"
                                        :key="item.id"
                                        class="mb-2 d-flex align-center"
                                      >
                                        <v-checkbox
                                          ref="chex"
                                          :disabled="
                                            extendedReport.report.status !=
                                              'Submitted'
                                          "
                                          v-model="
                                            extendedReport.quiz[iReport]
                                              .question[iQuestion]
                                              .answer_response_ids
                                          "
                                          :multiple="true"
                                          :label="item.content"
                                          @change="
                                            updateResultReport(
                                              iReport,
                                              iQuestion,
                                              currentQuestion
                                            )
                                          "
                                          :value="item.id"
                                          color="secondary"
                                          hide-details
                                          :required="currentQuestion.required"
                                        ></v-checkbox>

                                        <div
                                          class="mt-4 ml-1"
                                          v-if="item.help_text"
                                        >
                                          <v-tooltip bottom>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                mdi-alert-circle-outline
                                              </v-icon>
                                            </template>
                                            <span>{{ item.help_text }}</span>
                                          </v-tooltip>
                                        </div>
                                      </div>
                                    </div>

                                    <v-tooltip
                                      class="col-sm-1"
                                      v-if="currentQuestion.help_text"
                                      bottom
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          color="primary"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          class="pt-4"
                                        >
                                          mdi-alert-circle
                                        </v-icon>
                                      </template>
                                      <span>{{
                                        currentQuestion.help_text
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <v-btn
                                    v-if="
                                      extendedReport.report.status ==
                                        'Submitted'
                                    "
                                    @click="closeQuestions"
                                    small
                                    rounded
                                    color="secondary"
                                    :disabled="false"
                                    class="d-block ml-auto"
                                    >UPDATE</v-btn
                                  >

                                  <v-alert
                                    v-if="
                                      currentQuestion &&
                                        currentQuestion.help_html
                                    "
                                    border="left"
                                    color="#34a6c6"
                                    type="info"
                                    icon="mdi-alert-circle"
                                    class="mt-5 box-information"
                                    style="font-size: 14px"
                                  >
                                    <div
                                      v-html="
                                        currentQuestion &&
                                          currentQuestion.help_html
                                      "
                                    ></div>
                                  </v-alert>
                                </v-expansion-panel-content>
                              </template>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </template>
                </v-tabs>
              </v-card>

              <div class="d-flex justify-space-between my-8">
                <v-btn
                  v-if="this.extendedReport.report.status == 'Submitted'"
                  rounded
                  depressed
                  color="tertiary"
                  to="/org-dashboard"
                  >Cancel</v-btn
                >
                <v-btn
                  v-else
                  rounded
                  depressed
                  color="tertiary"
                  to="/org-dashboard"
                  >Back</v-btn
                >
                <v-btn
                  v-if="this.extendedReport.report.status == 'Submitted'"
                  rounded
                  color="primary"
                  :disabled="conditionsToSendReport"
                  @click="updateReport()"
                  >Publish report</v-btn
                >
              </div>
            </v-col>
          </template>
          <template v-else>
            <!-- loading -->
            <v-col cols="12">
              <p class="text-center text-overline mb-8">{{ reportMsg }}</p>
              <v-progress-linear
                color="secondary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-container>

    <!-- <v-dialog v-model="showModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure to leave?
        </v-card-title>
        <v-card-text>If you leave, all your changes will be lost.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveSite()">
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="showModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="onLiveEditing" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          A user is already working on this report
        </v-card-title>
        <v-card-text>You need to wait until this user finish.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveReport()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Role } from "@/helpers/roles";
import Header from "@/components/Header.vue";
import ReportService from "@/services/report.service";
import moment from "moment";
import ResultsService from "@/services/results.service";
import _ from "lodash";

export default {
  name: "Report",
  components: {
    Header,
    computedQuestion: () => import("@/components/TooltipQuizz.vue")
  },
  data() {
    return {
      testStringInputValue: "Vancouver, BC, Canada",
      error: null,
      apiError: "",
      reportId: this.$attrs.id,
      extendedReport: {},
      isLoaded: false,
      resultReport: [],
      reportMsg: "Loading Report",
      conditionsQuizStatus: {},
      openQuestion: [],
      isEditing: true,
      showModal: false,
      nextPage: null,
      riskProfiles: null,
      onLiveEditing: false,
      isPublishingReport: false
    };
  },
  computed: {
    isOrgAdmin() {
      let orgAdmin = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },

    conditionsToSendReport() {
      if (Object.values(this.conditionsQuizStatus).find(e => e == true)) {
        return true;
      } else {
        return false;
      }
    },

    isMobile() {
      return window.innerWidth > 760;
    },

    riskAssessment() {
      let assessment = null;
      this.riskProfiles.forEach(e => {
        if (
          this.riskScore >= e.risk_profile.min_score &&
          this.riskScore <= e.risk_profile.max_score
        ) {
          assessment = e.risk_profile.profile_name;
        }
      });
      if (assessment == null) {
        if (this.riskScore < -1) {
          return "Low";
        } else if (this.riskScore > 0) {
          return "High";
        }
      }

      return assessment;
    },

    riskScore() {
      let auxScore = [];
      let promScore = 0;
      if (this.isLoaded) {
        //calculate the risk_score for each quiz
        Object.values(this.extendedReport.quiz).forEach(quiz => {
          let auxQuizScore = 0;
          Object.values(quiz.question).forEach(q => {
            if (q.type == "radio") {
              //radio
              q.possible_responses.forEach(e => {
                if (e.id == q.answer_response_id) {
                  auxQuizScore = auxQuizScore + e.risk_score;
                } else {
                  //its a bug!
                }
              });
            } else {
              //checkbox
              q.answer_response_ids.forEach(id => {
                q.possible_responses.find(e => {
                  if (e.id == id) {
                    auxQuizScore = auxQuizScore + e.risk_score;
                  } else {
                    //its a bug!
                  }
                });
              });
            }
          });
          auxScore.push(auxQuizScore);
        });

        //calculate the prom risk_score
        auxScore.forEach(e => {
          promScore = promScore + e;
        });
      }

      return promScore;
    },

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  beforeMount() {
    if (this.isEditing) {
      window.addEventListener("beforeunload", this.preventNav);
      this.$once("hook:beforeDestroy", () => {
        window.removeEventListener("beforeunload", this.preventNav);
      });
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   if (this.isEditing) {
  //     this.preventNav(next, to);
  //     return;
  //   } else if (!this.onLiveEditing) {
  //     this.preventNav(next, to);
  //   } else {
  //     next();
  //   }
  // },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  methods: {
    async setOnLiveEditing() {
      await ReportService.update({
        id: this.reportId,
        data: {
          active_user_id: this.currentUser.user.id
        }
      });
    },

    sortedResponses(currentQuestion) {
      const sortedResponses = currentQuestion.possible_responses.slice();
      return _.orderBy(sortedResponses, "sort_order", "asc");
    },

    closeQuestions() {
      this.openQuestion = [];
    },

    leaveReport() {
      this.isEditing = false;
      if (this.isOrgAdmin) {
        this.$router.push("/org-dashboard");
      } else {
        //is wg
        this.$router.push("/dashboard");
      }
    },

    async leaveSite() {
      this.showModal = false;
      if (this.nextPage == "refresh") {
        location.reload();
      } else {
        await ReportService.update({
          id: this.reportId,
          data: {
            active_user_id: null
          }
        });
        //this.$router.push("/dashboard");
        this.nextPage();
      }
    },

    async preventNav(next, to) {
      if (this.extendedReport.report.status == "Submitted") {
        if (next.type == "beforeunload") {
          next.preventDefault();
          next.returnValue = "";
          this.nextPage = "refresh";
          this.showModal = true;
        } else {
          if (this.isPublishingReport) {
            await ReportService.update({
              id: this.reportId,
              data: {
                active_user_id: null
              }
            });
            this.nextPage = next;
            this.nextPage();
          } else {
            this.nextPage = next;
            if (to && to.path == "/login") {
              this.nextPage();
            } else {
              this.showModal = true;
            }
          }
        }
      } else {
        this.nextPage = next;
        this.nextPage();
      }
    },

    validateReport(s) {
      let findIndex = Object.values(
        this.extendedReport.quiz[s].question
      ).findIndex(e => e.condition == false);
      if (findIndex >= 0) {
        this.$set(this.conditionsQuizStatus, s, true);
        return true;
      } else {
        this.$set(this.conditionsQuizStatus, s, false);
        return false;
      }
    },

    updateResultReport(iReport, iQuestion, cq, radioId) {
      //find if is already there
      let indexReport = this.resultReport.findIndex(e => e.quiz_id == iReport);

      if (indexReport >= 0) {
        let indexQuestion = this.resultReport[indexReport].results.findIndex(
          i => i.question_id == iQuestion
        );
        if (indexQuestion >= 0) {
          //its already there
          if (cq.type === "text" || cq.type === "textarea") {
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses[0].response = cq.answer;
          }
          if (cq.type === "radio") {
            let indexAnswer = cq.possible_responses.findIndex(
              ip => ip.id == radioId
            );
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses[0].id = cq.answer_response_id;
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses[0].explanation =
              cq.possible_responses[indexAnswer].explanation;
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses[0].risk_score =
              cq.possible_responses[indexAnswer].risk_score;
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses[0].response =
              cq.possible_responses[indexAnswer].content;
          }
          if (cq.type === "checkbox") {
            this.resultReport[indexReport].results[
              indexQuestion
            ].responses.length = 0;
            cq.answer_response_ids.forEach(e => {
              let indexAnswer = cq.possible_responses.findIndex(
                ip => ip.id == e
              );
              this.resultReport[indexReport].results[
                indexQuestion
              ].responses.push({
                id: cq.possible_responses[indexAnswer].id,
                question_id: cq.possible_responses[indexAnswer].question_id,
                content: cq.possible_responses[indexAnswer].content,
                explanation: cq.possible_responses[indexAnswer].explanation,
                risk_score: cq.possible_responses[indexAnswer].risk_score,
                response: cq.possible_responses[indexAnswer].content
              });
            });
          }
        } else {
          //its a new question to be added
          if (cq.type === "text" || cq.type === "textarea") {
            this.resultReport[indexReport].results.push({
              quiz_id: parseInt(iReport),
              question_id: parseInt(iQuestion),
              responses: [
                {
                  type: cq.type,
                  response: cq.answer
                }
              ]
            });
          }
          if (cq.type === "radio") {
            let indexAnswer = cq.possible_responses.findIndex(
              ip => ip.id == radioId
            );
            this.$set(this.resultReport[indexReport], "results", []);
            this.resultReport[indexReport].results.push({
              quiz_id: parseInt(iReport),
              question_id: parseInt(iQuestion),
              responses: [
                {
                  id: cq.answer_response_id,
                  explanation: cq.possible_responses[indexAnswer].explanation,
                  risk_score: cq.possible_responses[indexAnswer].risk_score,
                  question_id: cq.possible_responses[indexAnswer].question_id,
                  response: cq.possible_responses[indexAnswer].content
                }
              ]
            });
          }
          if (cq.type === "checkbox") {
            let auxResponses = [];
            cq.answer_response_ids.forEach(e => {
              let indexAnswer = cq.possible_responses.findIndex(
                ip => ip.id == e
              );
              auxResponses.push({
                id: cq.possible_responses[indexAnswer].id,
                question_id: cq.possible_responses[indexAnswer].question_id,
                content: cq.possible_responses[indexAnswer].content,
                explanation: cq.possible_responses[indexAnswer].explanation,
                risk_score: cq.possible_responses[indexAnswer].risk_score,
                response: cq.possible_responses[indexAnswer].content
              });
            });
            this.resultReport[indexReport].results.push({
              quiz_id: parseInt(iReport),
              question_id: parseInt(iQuestion),
              responses: auxResponses
            });
          }
        }
      } else {
        //Cant find the report of the question!
      }
    },

    async addInfoToReport(info) {
      await ResultsService.submitResult({
        data: info
      });
    },

    async updateReport() {
      this.isEditing = false;
      this.isLoaded = false;
      this.reportMsg = "Updating & Creating new report";

      //create report (same this)
      //update that report (using our already updated resultReport)
      const reportData = {
        user_id: this.extendedReport.report.user_id,
        organization_id: this.extendedReport.report.organization_id,
        organization_name: this.extendedReport.report.organization_name,
        course_id: this.extendedReport.report.course_id,
        location_id: this.extendedReport.report.location_id,
        location_name: this.extendedReport.report.location_name,
        workergroup_id: this.extendedReport.report.workergroup_id,
        workergroup_name: this.extendedReport.report.workergroup_name,
        wgtype_id: this.extendedReport.workergroup.wgtype_id,
        debug_data: JSON.stringify({ x: 1, y: 2 })
      };

      const response = await ReportService.createReport(reportData);

      //FIX INITIAL DATA
      let auxParsedActualData_step_tow = [];
      this.extendedReport.results.forEach(e => {
        e.responses = [];
        let indexData = auxParsedActualData_step_tow.findIndex(
          i => i.quiz_id == e.quiz_id
        );
        if (indexData >= 0) {
          let indexQuestion = auxParsedActualData_step_tow[
            indexData
          ].results.findIndex(t => t.question_id == e.question_id);
          if (
            indexQuestion >= 0 &&
            e.response_data &&
            e.response_data.content
          ) {
            //it has multiple answers so its checkbox question
            e.response_data.response = e.response_data.content;
            auxParsedActualData_step_tow[indexData].results[
              indexQuestion
            ].responses.push(e.response_data);
            // return true;
          } else {
            if (
              e.question_data.type == "text" ||
              e.question_data.type == "textarea"
            ) {
              e.responses.push({
                type: e.question_data.type,
                response: e.response
              });
            } else {
              e.response_data.response = e.response_data.content;
              e.responses.push(e.response_data);
            }
            auxParsedActualData_step_tow[indexData].results.push(e);
          }
        } else {
          if (
            e.question_data.type == "text" ||
            e.question_data.type == "textarea"
          ) {
            e.responses.push({
              type: e.question_data.type,
              response: e.response
            });
          } else {
            e.response_data.response = e.response_data.content;
            e.responses.push(e.response_data);
          }
          auxParsedActualData_step_tow.push({
            report_id: response.data.report_id,
            quiz_id: e.quiz_id,
            results: [e]
          });
        }
      });

      //update this data

      await this.resultReport.forEach(e => {
        let indexData = auxParsedActualData_step_tow.findIndex(
          i => i.quiz_id == e.quiz_id
        );
        if (indexData >= 0) {
          e.results.forEach(r => {
            let indexQuestion = auxParsedActualData_step_tow[
              indexData
            ].results.findIndex(t => t.question_id == r.question_id);
            if (indexQuestion >= 0) {
              auxParsedActualData_step_tow[indexData].results[
                indexQuestion
              ].responses.length = 0;
              r.responses.forEach(rp => {
                auxParsedActualData_step_tow[indexData].results[
                  indexQuestion
                ].responses.push(rp);
              });
              // auxParsedActualData_step_tow[indexData].results[indexQuestion].responses = r.responses;
            } else {
              auxParsedActualData_step_tow[indexData].results.push(r);
            }
          });
        }
      });

      await auxParsedActualData_step_tow.forEach(e => {
        this.addInfoToReport({
          report_id: e.report_id,
          quiz_id: e.quiz_id,
          results: e.results
        });
      });

      const reportStatus = {
        status: "Submitted",
        active_user_id: null
      };

      await ReportService.update({
        id: response.data.report_id,
        data: reportStatus
      });

      this.isPublishingReport = true;

      if (this.isOrgAdmin) {
        this.$router.push("/org-dashboard");
      } else {
        //is wg
        this.$router.push("/dashboard");
      }
    },

    findAnswerInQuestionById(question, answer_id) {
      if (question.type == "checkbox") {
        if (question.answer_response_ids.find(e => e == answer_id)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (answer_id) {
          if (
            question.answer_response_id &&
            question.answer_response_id.find(e => e == answer_id)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    findQuestionById(quiz_id, question_id) {
      //HOTFIX FOR BACKEND ISSUE
      //THIS WAY, NOT VALIDATE QUIZ_ID
      let auxResponse = null;
      Object.keys(this.extendedReport.quiz).forEach(t => {
        if (
          this.extendedReport.quiz[t] &&
          this.extendedReport.quiz[t].question &&
          auxResponse == null
        ) {
          auxResponse = Object.values(
            this.extendedReport.quiz[t].question
          ).find(e => e.id == question_id);
        }
      });
      return auxResponse;
    },

    conditionCheck(hasConditions, cq) {
      if (cq.id) {
        //if the current question has an id, its a valid question
        if (hasConditions.length) {
          let conditionStatus = [];
          hasConditions.forEach(e => {
            let question_id = e.condition_question_id;
            let quiz_id = e.condition_quiz_id;
            let response_id = e.condition_response_id;
            let question = this.findQuestionById(quiz_id, question_id);
            if (question) {
              //if its not undefined
              let flagState = this.findAnswerInQuestionById(
                question,
                response_id
              );
              if (flagState) {
                //if the conditions is met
                conditionStatus.push(0);
              } else {
                conditionStatus.push(1);
              }
            } else {
              //its not a valid question, probably undefined for an error looping
              conditionStatus.push(1); //some of the conditions its not met
            }
          });

          if (conditionStatus.find(t => t != 0)) {
            this.$set(cq, "condition", true);
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    validationInput(iReport, iQuestion) {
      switch (this.extendedReport.quiz[iReport].question[iQuestion].type) {
        case "text":
        case "textarea":
          if (
            this.extendedReport.quiz[iReport].question[iQuestion].answer
              .length != 0
          ) {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              true
            );
            return true;
          } else {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              false
            );
            return false;
          }
        case "radio":
          if (
            this.extendedReport.quiz[iReport].question[iQuestion]
              .answer_response_id.length != 0
          ) {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              true
            );
            return true;
          } else {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              false
            );
            return false;
          }

        case "checkbox":
          if (
            this.extendedReport.quiz[iReport].question[iQuestion]
              .answer_response_ids.length != 0
          ) {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              true
            );
            return true;
          } else {
            this.$set(
              this.extendedReport.quiz[iReport].question[iQuestion],
              "condition",
              false
            );
            return false;
          }

        default:
          return false;
      }
    },

    //Format select field values
    formatSelectOptions: function(answers) {
      return answers.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },

    async initialize() {
      try {
        const response = await ReportService.getExtendedReport({
          id: this.reportId
        });

        if (
          response.data.report.active_user_id != null &&
          response.data.report.active_user_id != this.currentUser.user.id
        ) {
          this.onLiveEditing = true;
        } else {
          await this.setOnLiveEditing();
        }

        this.isLoaded = true;
        this.extendedReport = response.data;

        let reportQuizId = Object.keys(this.extendedReport.quiz);

        //--- SORT QUIZ
        var sortable = [];
        for (var vehicle in this.extendedReport.quiz) {
          sortable.push(this.extendedReport.quiz[vehicle]);
        }
        sortable.sort(function(a, b) {
          return a.info.sort - b.info.sort;
        });

        var objectQuizSort = {};

        sortable.forEach(e => {
          objectQuizSort[" " + e.info.id] = e;
        });

        this.extendedReport.quiz = objectQuizSort;
        //--- END SORT QUIZ

        reportQuizId.forEach(id => {
          this.resultReport.push({
            report_id: this.extendedReport.report.id,
            quiz_id: parseInt(id),
            results: []
          });
        });

        //Get range
        let auxEx = Object.keys(this.extendedReport.quiz);
        this.riskProfiles = this.extendedReport.quiz[
          auxEx[auxEx.length - 1]
        ].risk_profiles;

        //Format Report Date
        this.extendedReport.report.created_at = moment(
          this.extendedReport.report.created_at
        ).format("YYYY-MM-DD h:mm a");
      } catch (e) {
        console.log("error getting courses", e);
      }
    }
  }
};
</script>

<style scoped>
.box-information ::v-deep p span {
  background-color: transparent !important;
}
.box-information ::v-deep p span a {
  color: rgb(0, 0, 238) !important;
}
</style>
